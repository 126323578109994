<template>
  <div class="Taxi main">
    <van-notice-bar left-icon="volume-o" scrollable>{{tips}}</van-notice-bar>
    <div class="navBar flex">
      当前城市：
      <van-dropdown-menu>
        <van-dropdown-item v-model="city" :options="option" @change="changeCity"/>
      </van-dropdown-menu>
      <span class="noOpenCity" v-if="!showErrorMsg">
        （当前城市暂未开通服务）
      </span>
    </div>
    <div class="form">
      <div class="form-item flex" @click="onUpSite('start')">
        <div class="image flex">
          <img :src="require('@/img/icon_start.png')">
        </div>
        <div class="text flex">
          {{ startData.address }}
          <van-loading v-if="loading" size="15px"/>
        </div>
      </div>
      <div class="form-item flex" @click="onDownSite('end')">
        <div class="image flex">
          <img :src="require('@/img/icon_end.png')">
        </div>
        <div class="text flex">
          {{ endData.address }}
        </div>
      </div>
    </div>
    <van-field type="text" maxlength="11" class="inputPhone" input-align="center" disabled  readonly v-model="phone"
               placeholder="请输入手机号（必填）" clearable/>
    <button type="primary" size="large" :class=" [ 'calltaxi', canCallTaxi ? 'active': 'inactive', ] "
            @click="onClickCallTaxi">呼叫出租车
    </button>
    <div class="service flex">
      本次叫车服务由
      <a href="http://danjuantec.com">广西蛋卷科技有限公司</a>
      提供
    </div>
    <!--提示当前城市不支持下单-->
    <van-dialog v-model="show" confirm-button-text="知道了" confirm-button-color="#4D72E9" :lazy-render="false"
                @cancel="show = false">
      <div class="dialog">
        <img :src="require('@/img/noOpenCity.png')" style="width: 95%;height: auto;margin-bottom: 10px">
        <div class="text">当前城市暂未开通服务，敬请期待</div>
      </div>
    </van-dialog>
    <van-popup v-model="showMap" position="right" :style="{ height: '100%' ,width:'100%'}">
      <baiduMap v-bind:popup-show.sync="showMap"
                :direction="showType"
                :polygonDataFirst="polygonDataFirst"
                :polygonDataSecond="polygonDataSecond"
                :center="center"
                :city="city"
                :defaultAddress="defaultAddress"
                @onMapSuccess="onMapSuccess(arguments)">
      </baiduMap>
    </van-popup>
  </div>
</template>

<script>
  import Vue from 'vue';
  import taxiApi from "@/store/taxiApi";
  import baiduMap from "./map";
  import {Dialog, DropdownItem, DropdownMenu, Notify, Popup, Toast} from 'vant';
  import axios from "axios";
  import * as _ from 'lodash';
  import {mapState} from "vuex";

  Vue.use(Popup);
  Vue.use(DropdownMenu).use(DropdownItem);
  export default {
    name: "index",
    components: {
      baiduMap,
    },
    data() {
      return {
        show: false,
        city: '请选择',
        option: [],
        showErrorMsg: true, // 是否显示错误信息（当前城市暂未开通服务）

        startData: {address: "选择上车点", lng: '', lat: ''},
        endData: {address: "选择下车点", lng: '', lat: ''},
        phone: null,

        showMap: false,
        showType: '',

        loading: true,
        cityCenter: {}, // 城市中心点
        mapSearch: {}, // 上下车信息，传递给地图

        tips: null,

        center: {}, // 中心点
        defaultAddress: null,
      }
    },
    watch: {
      city: {
        handler(val) {
          if (_.isEmpty(this.option))
            return
          this.showErrorMsg = this.option.filter(res => res.value == val)[0].check
          if (!this.showErrorMsg)
            this.show = true;
        }
      },
      'callInfo': {
        handler(newVal) {
          if (!this.phone) {
            this.phone = this.callInfo.phone
          }
        },
        immediate: true
      },
      'showTabMenu': {
        handler(newVal) {
          console.log('参数', newVal)
          this.phone = newVal[0]
        }
      },
      mapSearch: {
        handler(val) {
          const {address, lat, lng} = val;
          if (address)
            this.defaultAddress = address;
          if (lat && lng)
            this.center = {lat, lng};
        },
        immediate: true,
        deep: true,
      },
      startData: {
        handler(val, old) {
          console.error('startData', val)
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState({
        showTabMenu: 'showTabMenu',
        smsAccessToken: 'smsAccessToken',
        callInfo: state => state.callInfo
      }),
      isStartInputEmpty() {
        return this.startData.address === '选择上车点';
      },
      isEndInputEmpty() {
        return this.endData.address === '选择下车点';
      },
      isPhoneInputValid() {
        return (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone));
      },
      canCallTaxi() {
        return !this.isStartInputEmpty && !this.isEndInputEmpty && this.isPhoneInputValid;
      }
    },
    mounted() {
      this.getOpenCityList();
      this.getLocationAddress();
    },
    methods: {
      // 获取运营城市列表
      async getOpenCityList() {
        try {
          const r = await taxiApi.getOpenCityList();
          const {data, status, message} = r;
          if (status == '0') {
            this.option = []
            let defaultCity = [{
              check: true,
              lat: 22.822754,
              lng: 108.363949,
              value: '请选择',
              text: '请选择',
              address: '请选择'
            }];
            // TODO #bug 这里的forEach 并不能实现等待多次异步请求结果的作用
            data.forEach(async res => {
              let itemCity = {
                check: true,
                lat: res.centerLat,
                lng: res.centerLng,
                value: res.placeName,
                text: res.placeName,
                address: null,
              }
              this.option.push(itemCity);
            })
            this.option = defaultCity.concat(this.option);
          } else {
            Notify({type: 'warning', message: `运营城市获取失败：${message}`});
          }
        } catch (e) {
          Notify({type: 'danger', message: `运营城市获取异常：${e}`});
        }
      },

      /*
      * 选择上车地址
      * */
      onUpSite(type) {
        if (this.city == '请选择') {
          Toast.fail('未选择服务城市');
          return
        }
        if (this.startData.lat && this.startData.lng) {
          this.mapSearch = {...this.startData}
        }
        this.showMap = true;
        this.showType = type;
      },
      /*
      * 选择下车地址
      * */
      onDownSite(type) {
        if (this.city == '请选择') {
          Toast.fail('未选择服务城市');
          return
        }
        if (this.endData.lat && this.endData.lng) {
          this.mapSearch = {...this.endData}
        }
        this.showMap = true;
        this.showType = type;
      },
      /*
      * 选择地址的回调函数
      * */
      onMapSuccess(data) {
        const mapSearchData = data[0];
        const type = data[1]
        if (!_.isEmpty(mapSearchData)) {
          if (this.showType == 'start') {
            this.startData.address = mapSearchData.title ? mapSearchData.title : mapSearchData.address || null;
            this.startData.lat = mapSearchData.lat;
            this.startData.lng = mapSearchData.lng;
          } else if (this.showType == 'end') {
            this.endData.address = mapSearchData.title ? mapSearchData.title : mapSearchData.address || null;
            this.endData.lat = mapSearchData.lat;
            this.endData.lng = mapSearchData.lng;
          }
          if (this.showType != type)
            this.defaultAddress = null;
        }
      },
      /*
      * 定位获取当前位置信息
      * */
      getLocationAddress() {
        var geolocation = new BMap.Geolocation();
        geolocation.enableSDKLocation();
        geolocation.getCurrentPosition(
          (success) => {
            switch (geolocation.getStatus()) {
              case BMAP_STATUS_SUCCESS:
                const point = success.point;
                const address = success.address;
                this.city = address.city;
                // this.startData.address = address.district || address.street;
                // this.startData.lat = point.lat;
                // this.startData.lng = point.lng;
                this.$set(this, 'center', {
                  lat: point.lat,
                  lng: point.lng
                })
                this.tips = '浏览器获取GPS定位数据可能存在误差'
                break;
              case BMAP_STATUS_INVALID_REQUEST:
                this.tips = '定位服务非法请求'
                break;
              case BMAP_STATUS_PERMISSION_DENIED:
                this.tips = '定位服务没有权限'
                break;
              case BMAP_STATUS_SERVICE_UNAVAILABLE:
                this.tips = '定位服务不可用，请手动选择'
                break;
              case BMAP_STATUS_TIMEOUT:
                this.tips = '定位请求超时'
                break;
            }
            this.loading = false;
          },
          (e) => {
            console.error('定位失败：', e);
            this.loading = false;
          },
          {
            enableHighAccuracy: true,
            timeout: 20000,
            SDKLocation: true,
          });

        setTimeout(() => {
          this.loading = false;
        }, 3000)
      },

      changeCity(e) {
        this.startData = {address: "选择上车点", lng: '', lat: ''};
        this.endData = {address: "选择下车点", lng: '', lat: ''};
        this.mapSearch = this.option.filter(res => res.value == e)[0]
      },

      /*
     * 叫车
     * */
      onClickCallTaxi() {
        if (!this.canCallTaxi) {
          return;
        }
        if (!this.showErrorMsg) {
          this.show = true
          return;
        }
        const externalOrder = {
          mobile: this.phone,
          origin: this.startData.address,
          originLng: this.startData.lng,
          originLat: this.startData.lat,
          dest: this.endData.address,
          destLng: this.endData.lng,
          destLat: this.endData.lat,
          source: 6,  // 6. 手机H5网页
          channel: 5,  // 0. 蛋卷 1.北方 2.出行南宁APP 3.市民卡APP 4.智桂通APP 5.95128自助服务
          type: 0, // 0 实时。1预约
          name: `${this.phone}`
        }

        Toast.loading({
          mask: true,
          message: '正在下发叫车信息...',
          duration: 0,
        });

        axios({
          method: "post",
          url: "/api/external/createOrderFromH5",
          baseURL: this.$store.state.taxiApiUrl,
          headers: {"content-type": "application/json"},
          data: externalOrder,
        }).then(res => {
          if (res.data.errCode == 0) {
            this.$router.replace('/success');
            this.showBottomAd = false;
          } else {
            let msg = '叫车请求失败，请稍后重试！';
            msg = msg + res.data.message;
            Dialog.alert({
              title: '提示',
              message: msg,
            }).then(() => {
              // on close
            });
          }
        }).catch(err => {
          Dialog.alert({
            title: '异常提示',
            message: err,
          }).then(() => {
            // on close
          })
        }).finally(err => {
          Toast.clear();
        })

      },
    },
  }
</script>

<style scoped lang="scss">
  /*不支持当前城市弹窗*/
  .dialog {
    padding: 50px 30px 30px;

    .text {
      font-family: PingFang SC;
      font-weight: 400;
      color: #20252C;
    }
  }

  .Taxi {
    width: 100%;
    height: 100%;

    .navBar {
      width: 90%;
      margin: 0 auto;
      height: 50px;
      display: flex;
      align-items: center;
      font-family: PingFang SC;

      ::v-deep .van-dropdown-menu__bar {
        background-color: #EEEEEE;
        box-shadow: none;
      }

      ::v-deep .van-dropdown-menu__title {
        font-weight: bold;
        color: #222222;
      }

      ::v-deep .van-dropdown-menu__title::after {
        border-color: transparent transparent #222222 #222222;
      }

      .noOpenCity {
        font-weight: 400;
        color: #CB1C1C;
      }
    }

    .form {
      padding: 10px;
      border-radius: 5px;
      width: 90%;
      min-height: 110px;
      margin: 0 auto;
      background-color: #fff;

      .form-item {
        height: 55px;
        border-bottom: 1px solid #dedddd;

        .image {
          width: 20px;
          height: 100%;
          align-items: center;
          justify-content: center;

          & > img {
            width: 6px;
            height: 6px;
          }
        }

        .text {
          font-weight: bold;
          text-align: left;
          font-size: 14px;
          padding-left: 10px;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .form-item:last-child {
        border: none;
      }
    }

    .inputPhone {
      border: none;
      width: 95%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
      border-radius: 6px;
      font-size: 16px;
      font-family: PingFang SC;
      margin: 20px auto;
    }

    .calltaxi.active {
      background: rgba(34, 34, 34, 1);
      color: rgba(254, 216, 64, 1);
    }

    .calltaxi.inactive {
      background: rgb(123, 123, 123);
      color: rgb(193, 155, 0);
    }

    .calltaxi {
      height: 50px;
      box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
      margin: 0 auto;
      border-radius: 6px;

      width: 95%;
      color: white;
      margin-top: 50px;
      outline: none;
      border: none;
      font-size: 18px;
      font-family: PingFang SC;
      text-align: center;
      font-weight: bold;
      line-height: 25px;

      opacity: 1;
    }

    .service {
      font-size: 12px;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: center;
    }
  }
</style>
